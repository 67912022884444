import { toast } from 'react-toastify'
import ReactGA from 'react-ga'
import { logout } from '../service/user'
import notify from '../mapper/notify'
import PIX from '../const/pix'

const errosTypes = ['Error', 'AxiosError']

export const validation = (data) => {
  let base = {}

  if (data?.config?.meta?.requestStartedAt) {
    const elapsedTime = new Date().getTime() - data.config.meta.requestStartedAt //milliseconds
    const range = Math.ceil(elapsedTime / 100) * 100
    const action = `${data.config.method} ${data.config.url
      .split('?')[0]
      .replace(/STO[-a-z0-9]{37}/gim, '{storeId}')
      .replace(/USR[-a-z0-9]{37}/gim, '{userId}')
      .replace(/email.*@.*/gim, 'email/{email}')} em até ${range} ms`
    ReactGA.event({
      category: data?.message === 'Network Error' ? `API Call ${data?.message}` : 'API Call',
      action,
      value: elapsedTime
    })
  }

  if (!data) return base

  if (data?.message === 'Network Error') {
    toast.error('Serviço indisponível no momento, tente novamente mais tarde')
    return base
  }

  if (data?.config?.url.indexOf('/auth/login') >= 0 && errosTypes.includes(data?.name)) {

    base.state = false
    let error = 'Erro ao tentar acessar.'
    if (data.message.indexOf('401') >= 0) {
      error = data.response.data.message !== 'Request Unauthorized.' ? data.response.data.message : 'Usuário ou senha não conferem.'
    }
    base.data = { error }
    logout()
    return base
  }
  if (
    (data.config?.url.indexOf('/reset-password/email') >= 0 ||
      data.config?.url.indexOf('/auth/login') >= 0) &&
    data?.data?.cause
  ) {
    base.state = false
    base.data = {
      error: data.data.cause.errors.reduce((strAc, str) => `${strAc} ${str.message}`, '')
    }

    return base
  }

  if (data?.config?.url.indexOf('/store/merchant/account/') >= 0 && data?.data?.cause) {
    base.state = false
    base.data = {
      error: data.data.cause.errors.reduce((strAc, str) => `${strAc} ${str.message}`, '')
    }
    return base
  }

  if (data?.data?.cause && data?.data?.cause?.errors && data?.data?.responseCode === 404) {
    const session = data.data.cause.errors.find((elm) => elm.error === 'SESSION_NOT_FOUND')
    const hasLogged = window.localStorage.getItem('AUTH_TOKEN')

    if (session && hasLogged) {
      base = { state: false, data: null }
      toast.error('Sessão expirada. Faça seu login novamente')
      logout()
      ReactGA.event({
        category: 'Login',
        action: 'Sessão expirada'
      })
      return base
    }
  }

  if (data?.status < 200 || data?.status > 299) {
    const defaultErrorMessage = data.data?.cause || 'Falha no servidor'
    base.state = false
    base.data = null
    if (data.status === 404 && data.data?.serviceName === 'Get PIX Keys') {
      return base
    }
    if (data.data?.serviceName === 'Delete PIX Key') {
      return data.data
    }

    if (data?.status === 400 && data?.config?.url.indexOf('/pix') >= 0) {
      const pixKeyError =
        data.config.url.indexOf('/pix?store_id=') >= 0 &&
        PIX.TYPE_ERROR[data.data.cause.errors[0].error]
      const PixClaimError =
        data.config.url.indexOf('/pix/claim/') >= 0 &&
        data.data.cause.errors[0].error === 'PIX_CLAIM_ALREADY_EXISTS'
      if (pixKeyError || PixClaimError) {
        base.data = { error: data.data.cause.errors[0].error }
        return base
      }
      return data
    }

    toast.error(
      data.data.cause && data.data.cause.errors
        ? notify(data.data.cause.errors[0].message, data.data.cause.errors[0].message)
        : defaultErrorMessage
    )
    return base
  }

  return data
}

export default { validation }
